@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600&display=swap');
html,
body {
  @apply text-dark  font-primary;
  background-color: white;
}

body.ar {
  @apply text-dark  font-arabic;
  direction: rtl;
  background-color: white;
}

body {
  margin: 0;
  @apply font-primary font-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
